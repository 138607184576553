import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
    background: "transparent",
  },
}));

function MediaEmbed(props) {
  const classes = useStyles();

  const { width, height, ...otherProps } = props;

  return (
    <iframe
      className={classes.root}
      title="Media Embed"
      allow="autoplay *; encrypted-media *;"
      frameBorder={0}
      style={{
        height: props.height,
        maxWidth: props.width,
      }}
      {...otherProps}
    />
  );
}

export default MediaEmbed;
