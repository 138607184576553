import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AppleMusicEmbed from "./AppleMusicEmbed";

function ProductReviewSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container maxWidth="sm" disableGutters={true}>
          <AppleMusicEmbed url="https://adilo.bigcommand.com/watch/rabpARaM" />
        </Container>
      </Container>
    </Section>
  );
}

export default ProductReviewSection;
