import React from "react";
import "./../components/styles.css";
import Meta from "./../components/Meta";
import Navbar from "./../components/Navbar";
import HeroSection from "./../components/HeroSection";
import ProductReviewSection from "./../components/ProductReviewSection";
import InventorySection from "./../components/InventorySection";
import YoutubeEmbed from "../components/YoutubeEmbed";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="https://res.cloudinary.com/devdash54321/image/upload/v1648007475/bambuddies/Cannabis_leaf_2.svg"
        logoInverted="https://res.cloudinary.com/devdash54321/image/upload/v1648007475/bambuddies/Cannabis_leaf_2.svg"
      />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="BAM Buddies Flower Reviews"
        subtitle="No More Unpredictable Trips!"
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText="Text #BAMBuddies To +1.470.480.8445 To Join The Community"
        buttonColor="primary"
        buttonPath="/#features"
      />
      <ProductReviewSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Product Review"
        subtitle=""
      />
      <InventorySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default IndexPage;
